import React from "react";
// import group7 from '../assets/images/group7.png';

export const AboutSection = () => {
  return (
    <div className="mt-24 px-8">
      <div className="flex flex-col md:flex-row justify-center items-center">
        {/* Conditionally apply text size and rotation for different screen sizes */}
        <h1 className="text-4xl md:text-8xl font-bold mb-8 md:mb-0 md:absolute md:translate-y-1/2 md:left-0 md:top-1/2 md:-rotate-90 md:origin-left md:mt-[1000px] md:ml-20">
          WHO TF IS HE?
        </h1>
        {/* Image container */}
        <div className="relative">
          <img className="w-full max-w-screen-lg mx-auto" alt="Asset INU" src="/group7a.png" />
        </div>
      </div>
      <p className="max-w-screen-lg text-xl md:text-3xl font-normal mt-8 text-center">
        <span className="font-medium">HOBBIES: </span>
        <span className="font-light">
          Being a menace for fun, burning $$$, clubbing, <b>and sometimes being a crypto coin (his only redeeming quality).</b>
        </span>
      </p>
    </div>
  );
};

export default AboutSection;
