import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="relative mt-24">
      <img src="/footer.png" className="w-full" alt="Footer background" />
      <div className="absolute inset-0 flex flex-col items-center justify-center px-4 sm:px-12">
        <div className="text-center mb-2 sm:mb-4 bg-white bg-opacity-80 p-4 sm:p-12 border-2 border-black">
          <h2 className="text-2xl sm:text-4xl font-bold mb-2">YK HE LOVES THE ATTENTION</h2>
          <p className="text-lg sm:text-xl">Join the Swag Community to follow Swag Inu on his mischief</p>
        </div>
        <Link to="/" className="bg-black text-white py-2 sm:py-4 px-4 sm:px-8 mb-2 sm:mb-4 text-4xl sm:text-6xl">BUY SWAG</Link>
        <div className="flex space-x-2 sm:space-x-4">
          <a href="https://x.com/theswaginu" target="_blank" rel="noopener noreferrer">
            <img src="/twitter.png" alt="Twitter" className="h-16 w-16 sm:h-25 sm:w-25" />
          </a>
          <a href="https://x.com/theswaginu" target="_blank" rel="noopener noreferrer">
            <img src="/telegram.png" alt="Telegram" className="h-16 w-16 sm:h-25 sm:w-25" />
          </a>
          <a href="https://x.com/theswaginu" target="_blank" rel="noopener noreferrer">
            <img src="/chart.png" alt="Chart" className="h-16 w-16 sm:h-25 sm:w-25" />
          </a>
        </div>
        {/* Disclaimer Text */}
        <div className="text-center mt-4 mb-4 sm:mb-4 bg-white bg-opacity-80 p-4 sm:p-4 border-2 border-black">
          <p className="text-lg sm:text-xl">$SWAG is a meme coin with no intrinsic value or expectation of financial return.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
