import { BrowserRouter } from "react-router-dom";

import Navbar from "./components/Navbar";
// import Hero from "./components/Hero";
// import Features from "./components/Features.jsx";
import VideoPlayer from "./components/Video.jsx";
// import Testimonial from "./components/Testimonial";
// import CallToAction from "./components/CallToAction";
import Swag from "./components/swag.jsx";
import Read from "./components/read.jsx";
import Footer from "./components/Footer";
import Who from "./components/Who";
import Slider from "./components/Slider.js";
import Collage from "./components/collage.jsx";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <VideoPlayer />
      {/* <Hero /> */}
      <Who />
      <Swag />
      {/* <Features /> */}
      <Slider />
      <Collage />
      <Read />
      {/* <Testimonial />
      <CallToAction /> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
