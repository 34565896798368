import React from "react";

export const Collage = () => {
  return (
    <div className="relative mt-24 px-8">
      <div className="flex items-center justify-center">
        {/* Hide text on small screens and show rotated text on larger screens */}
        <div className="hidden md:block absolute md:transform md:-translate-y-1/2 md:left-0 md:top-1/2 md:-rotate-90 md:origin-left md:ml-20">
          <h1 className="text-8xl font-bold whitespace-nowrap">DON'T LOOK</h1>
        </div>
        {/* Image container */}
        <div>
          <img className="w-full max-w-screen-lg" alt="Collage" src="/all.png" />
        </div>
        {/* Hide text on small screens and show rotated text on larger screens */}
        <div className="hidden md:block absolute md:transform md:translate-y-1/2 md:right-0 md:top-1/2 md:rotate-90 md:origin-right md:mr-20">
          <h1 className="text-8xl font-bold whitespace-nowrap">NOTHING HERE</h1>
        </div>
      </div>
    </div>
  );
};

export default Collage;
