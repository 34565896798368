import React from 'react';


class VideoPlayer extends React.Component {
  render() {
    return (
      // Ensured that the container takes the full width
      <div className="video-player-container top-0 left-0 w-full z-40">
        <div>
          <video controls className='w-full'
          poster="/thumbnail.png">
            <source src="/swagvideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
