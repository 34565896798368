import React from "react";

export const Read = () => {
  return (
    <div className="relative md:mt-24 px-8">
      <div className="flex flex-col md:flex-row items-center justify-center">
        {/* Make text horizontal and smaller on small screens */}
        <div className="text-center md:absolute md:-translate-y-1/2 md:left-0 md:top-1/2 md:-rotate-90 md:origin-left md:ml-20">
          <h1 className="text-4xl md:text-8xl mt-8 md:mt-0 font-bold whitespace-nowrap mb-8 md:mb-0">/biz /biz /biz</h1>
        </div>
        {/* Image container */}
        <div>
          <img className="w-full max-w-screen-lg mx-auto" alt="Swag Paris" src="/read.png" />
        </div>
        {/* Hide text on small screens */}
        <div className="hidden md:block md:absolute md:transform md:translate-y-1/2 md:right-0 md:top-1/2 md:rotate-90 md:origin-right md:mr-20">
          <h1 className="text-8xl font-bold whitespace-nowrap">READ READ READ</h1>
        </div>
      </div>
    </div>
  );
};

export default Read;
