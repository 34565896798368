import React from "react";

export const Swag = () => {
  return (
    <div className="relative mt-24 px-8">
      {/* <div className="flex items-center justify-center"> */}
      <div className="flex flex-col md:flex-row items-center justify-center">
        {/* Conditional rendering for mobile: the text appears above the image */}
        <div className="text-center md:absolute md:translate-y-1/2 md:right-0 md:top-1/2 md:rotate-90 md:origin-right md:mr-20">
          <h1 className="text-4xl md:text-8xl mb-8 font-bold whitespace-nowrap">SWAG IN PARIS</h1>
        </div>
        {/* Image container */}
        <div>
          <img className="w-full max-w-screen-lg" alt="Swag Paris" src="/swagparis.png" />
        </div>
      </div>
    </div>
  );
};

export default Swag;
