import React from "react";

const NavBar = () => {
  return (
    <div className="fixed top-0 w-full z-50 px-4">
      <div className="bg-black overflow-hidden w-full">
        <div className="flex justify-center items-center gap-8 md:gap-[65px] mx-auto px-22 py-4">
          {/* Conditional rendering using Tailwind's responsive classes */}
          <div className="hidden md:inline-flex font-medium text-white text-[18px] tracking-[4.32px] leading-[28.8px] whitespace-nowrap">
            HOME
          </div>
          <div className="font-medium text-white text-[18px] tracking-[4.32px] leading-[28.8px] whitespace-nowrap">
            BUY SOON
          </div>
          <div className="hidden md:inline-flex font-medium text-white text-[18px] tracking-[4.32px] leading-[28.8px] whitespace-nowrap">
            CHART SOON
          </div>
          <a href="https://x.com/theswaginu" target="_blank" rel="noopener noreferrer">
          <div className="font-medium text-white text-[18px] tracking-[4.32px] leading-[28.8px] whitespace-nowrap">
            TWITTER
          </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
